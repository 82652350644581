import api from './api';

export const getClients = async () => {
    try {
        const response = await api.get(`api/user-clients`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка входа');
    }
};

export const updateClients = async (id) => {
    try {
        const response = await api.patch(`api/user-client-update/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка входа');
    }
};
