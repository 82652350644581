import React, { useState } from 'react';
import { FaSignOutAlt, FaBell, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

export default function Settings() {
    const [notificationsEnabled, setNotificationsEnabled] = useState(true);
    const [volume, setVolume] = useState(50); // Значение громкости от 0 до 100

    const handleLogout = () => {
        // Логика для выхода из профиля
        console.log('Выход из профиля');
        // Очистка localStorage и обновление страницы
        localStorage.removeItem('token');
        window.location.reload();
    };

    const toggleNotifications = () => {
        setNotificationsEnabled(!notificationsEnabled);
        // Логика для включения/выключения уведомлений
        console.log('Уведомления ' + (notificationsEnabled ? 'выключены' : 'включены'));
    };

    const handleVolumeChange = (e) => {
        setVolume(e.target.value);
        // Логика для управления громкостью (если WebView позволяет)
        console.log('Громкость установлена на ' + e.target.value);
    };

    return (
        <div className="w-full h-[87vh] p-4 bg-gray-100">
            <h1 className="text-2xl font-bold mb-4">Настройки</h1>
            <div className="space-y-4">
                <div
                    className="flex items-center justify-between bg-white p-4 rounded shadow"
                >
                    <div className="flex items-center space-x-2">
                        <FaVolumeUp />
                        <span>Громкость</span>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={volume}
                        onChange={handleVolumeChange}
                        className="w-2/3"
                    />
                    <div className="text-gray-400">
                        {volume}%
                    </div>
                </div>
                <div
                    className="flex items-center justify-between bg-white p-4 rounded shadow cursor-pointer"
                    onClick={toggleNotifications}
                >
                    <div className="flex items-center space-x-2">
                        <FaBell />
                        <span>Уведомления</span>
                    </div>
                    <div className="text-gray-400">
                        {notificationsEnabled ? 'Включены' : 'Выключены'}
                    </div>
                </div>
                <div
                    className="flex items-center justify-between bg-red-500 text-white p-4 rounded shadow cursor-pointer"
                    onClick={handleLogout}
                >
                    <div className="flex items-center space-x-2">
                        <FaSignOutAlt />
                        <span>Выход из профиля</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
