import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {useEffect} from "react";
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function BottomBar({page}) {
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        page(value)
    }, [value])

    return (
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#01193b' }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    backgroundColor: '#162235',
                    '.Mui-selected': {
                        color: '#ffffff',
                    },
                    '.MuiBottomNavigationAction-label': {
                        color: '#ffffff',
                    }
                }}
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} sx={{ color: value === 0 ? '#ffffff' : '#b0b0b0' }}/>
                <BottomNavigationAction label="Users" icon={<GroupIcon />} sx={{ color: value === 1 ? '#ffffff' : '#b0b0b0' }}/>
                {/*<BottomNavigationAction label="Profile" icon={<AdminPanelSettingsIcon />} sx={{ color: value === 2 ? '#ffffff' : '#b0b0b0' }}/>*/}
                <BottomNavigationAction label="Setting" icon={<SettingsIcon />} sx={{ color: value === 3 ? '#ffffff' : '#b0b0b0' }}/>
            </BottomNavigation>
        </Box>
    );
}
