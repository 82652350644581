import React from "react";
import {useEffect} from "react";
import Home from "../../pages/Home/Home";
import Settings from "../Setting/Settings";
import Clients from "../Clients/Clients";

export function ContentHandler(contentId){
    switch (contentId){
        case 0:
            return <Home/>
        case 1:
            return <Clients/>
        case 2:
            return <Settings/>
        default: return <div className={'bg-red-400 h-screen w-screen text-white flex flex-col justify-center items-center'}> 404 NOT found</div>
    }
}
