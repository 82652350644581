import api from './api';

export const getOrders = async () => {
    try {
        const response = await api.get(`api/orders`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка получения заказов');
    }
};



export const getCourierOrders = async () => {
    try {
        const response = await api.get(`api/courier-orders`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка получения ваших заказов');
    }
};

export const getUnAssignOrders = async () => {
    try {
        const response = await api.get(`api/courier-un-assign-orders`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка получения заказов');
    }
};

export const orderPending = async (data) => {
    try {
        const response = await api.patch(`api/orders-pending`,data);
        return response.data;
    } catch (error) {
        console.log(error)
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка при смене статуса');
    }
};


export const orderCompleted = async (data) => {
    try {
        const response = await api.patch(`api/orders-completed`,data);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка при смене статуса');
    }
};

export const orderOnHold = async (data) => {
    try {
        const response = await api.patch(`api/orders-hold`,data);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message || 'Ошибка при смене статуса');
    }
};
