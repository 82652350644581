import './App.css';
import {useEffect, useState} from "react";
import BottomBar from "./components/BottomBard/BottomBar";
import Main from "./pages/Main/Main";
import {ContentHandler} from "./layers/Content/ContentHandler";
import Auth from "./layers/Auth/Auth";
import SplashScreen from "./components/SplashScreen/SplashScreen"; // Импортируем SplashScreen

function App() {
    const [page, setPage] = useState(0);
    const [isAuth, setIsAuth] = useState(false);
    const [isOnlyOrders, setIsOnlyOrders] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Для управления показом splash screen

    const fetchPage = (id) => setPage(id);

    const handleOnlyOrders = (state) => setIsOnlyOrders(state);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            setIsAuth(true);
        }
    }, []);

    useEffect(() => {
        // Показываем splash screen в течение 2 секунд
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
    }, []);

    useEffect(() => {
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;

        function urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.register('service-worker.js')
                .then((registration) => {
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(publicKey)
                    });
                })
                .then((subscription) => {
                    const userId = 9;
                    if (!userId || !subscription) {
                        console.error('User ID или подписка не найдены');
                        return;
                    }

                    fetch('https://driver.osonexpress.uz/water/water/subscribe', {
                        method: 'POST',
                        body: JSON.stringify({
                            userId: userId,
                            subscription: subscription
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => {
                            if (!response.ok) {
                                return response.json().then(error => {
                                    throw new Error(error.error);
                                });
                            }
                            console.log('Подписка успешно отправлена на сервер');
                        })
                        .catch(error => {
                            console.error('Ошибка подписки на push-уведомления:', error);
                        });
                })
                .catch(error => {
                    console.error('Ошибка регистрации Service Worker:', error);
                });
        }
    }, []);




    return (
        //--- root ---
        <div className="w-screen h-screen overflow-x-hidden">
            {/*main container*/}
            {isLoading ? <SplashScreen /> : (
                isAuth ? <Auth /> :
                    <div className="w-full h-fit flex flex-col overflow-y-scroll">
                        <Main page={ContentHandler(page)} />
                        <BottomBar page={fetchPage} />
                    </div>
            )}
            {/*end main container*/}
        </div>

        //--- end root ---
    );
}

export default App;
