import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import uzbekistan from '../../../assets/img/flags/uzbekistan.png'
import russia from '../../../assets/img/flags/russia.png'
import usa from '../../../assets/img/flags/united-states.png'

export default function LanguageSelect() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <img width={32} height={32} src={uzbekistan}/>
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleClose}><img width={32} height={32} src={uzbekistan}/></MenuItem>
                <MenuItem onClick={handleClose}><img width={32} height={32} src={russia}/></MenuItem>
                <MenuItem onClick={handleClose}><img width={32} height={32} src={usa}/></MenuItem>
            </Menu>
        </div>
    );
}
