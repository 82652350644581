// src/api.js
import axios from 'axios';

// Получаем базовый URL из переменной окружения
const BASE_URL = process.env.REACT_APP_URL;
const token = localStorage.getItem('token')
const headers = token ? {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
} : {
    'Content-Type': 'application/json',
}
const api = axios.create({
    baseURL: BASE_URL,
    headers: headers,
});

export default api;
