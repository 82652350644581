import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const data = [
    { value: parseInt( localStorage.getItem('total') || 0), label: 'total' },
    { value: parseInt(localStorage.getItem('pending') || 0), label: 'pending' },
    { value: parseInt(localStorage.getItem('completed') || 0), label: 'completed' },
];

const size = {
    width: window.innerWidth - 40,
    height: 200,
};

export default function DashboardChart() {
    return (
        <PieChart
            series={[
                {
                    data,
                    innerRadius: 80,
                }
            ]}
            {...size}
            sx={{
                [`& .MuiChartsLegend-series text`]: {
                    fill: 'white', // Меняем цвет текста в легенде на белый
                },
            }}
        />
    );
}
