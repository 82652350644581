import React, { useEffect, useState } from "react";
import {Card, Drawer, List, Avatar, Typography, Input, Button, Form, Upload, message, Divider, Space} from "antd";
import { FiPhone, FiHome, FiCamera, FiFileText } from "react-icons/fi";
import { getClients, updateClients } from "../../api/Clients";
import { UploadOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { Search } = Input;

export default function Clients() {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [visible, setVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [form] = Form.useForm();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const data = await getClients();
            setClients(data);
            setFilteredClients(data);
        } catch (e) {
            console.log(e);
        }
    };

    const updateClient = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();

            for (const key in values) {
                if (values[key] !== undefined) {
                    formData.append(key, values[key]);
                }
            }

            if (selectedClient) {
                await updateClients(selectedClient.id, formData);
                message.success('Клиент успешно обновлен');
                fetchClients();
                onClose();
            }
        } catch (error) {
            console.log(error);
            message.error('Ошибка при обновлении клиента');
        }
    };

    const showDrawer = (client) => {
        setSelectedClient(client);
        form.setFieldsValue({
            fullname: client.fullname,
            phoneNumber: client.client_card.phone_number,
            address: client.address,
            cashback: client.client_card.cashback,
            bottles_count: client.client_card.bottles_count,
            second_address: client.client_card.second_address,
        });
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setEditMode(false);
    };

    const handleSearch = (value) => {
        setSearchTerm(value.toLowerCase());
        const filtered = clients.filter(client =>
            client.fullname.toLowerCase().includes(value.toLowerCase()) ||
            client.phoneNumber.includes(value) ||
            (client.address && client.address.toLowerCase().includes(value.toLowerCase()))
        );
        setFilteredClients(filtered);
    };

    return (
        <div style={{ padding: 16 }}>
            <Search
                placeholder="Поиск по имени, номеру телефона или адресу"
                enterButton
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: 16 }}
            />

            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={filteredClients}
                renderItem={(client) => (
                    <List.Item>
                        <Card
                            onClick={() => showDrawer(client)}
                            style={{
                                borderRadius: 12,
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                backgroundColor: "#ffffff",
                            }}
                            bodyStyle={{ padding: 16 }}
                        >
                            <Card.Meta
                                avatar={<Avatar size="large" style={{ backgroundColor: "#1890ff" }}>{client.fullname[0]}</Avatar>}
                                title={<Title level={4} style={{ margin: 0 }}>{client.fullname}</Title>}
                                description={<Text type="secondary">{client.phoneNumber}</Text>}
                            />
                        </Card>
                    </List.Item>
                )}
            />

            <Drawer
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Title level={5}>{selectedClient?.fullname}</Title>
                        <Button onClick={() => setEditMode(!editMode)}>{editMode ? "Отменить" : "Редактировать"}</Button>
                    </div>
                }
                placement="bottom"
                closable={true}
                onClose={onClose}
                open={visible}
                height="80vh"
                bodyStyle={{ padding: 16, backgroundColor: "#f0f2f5" }}
                headerStyle={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, backgroundColor: "#fafafa" }}
            >
                {selectedClient && (
                    <>
                        {!editMode && (
                            <>
                                <Divider orientation="left">Информация</Divider>
                                <div style={{ marginBottom: 16 }}>
                                    <Space direction="vertical" size="large">
                                        <Space>
                                            <FiPhone style={{ marginRight: 8, fontSize: 20 }} />
                                            <Text strong>Номер телефона:</Text>
                                            <Text>{selectedClient.client_card.phone_number || 'Отсутствует'}</Text>
                                        </Space>
                                        <Space>
                                            <FiHome style={{ marginRight: 8, fontSize: 20 }} />
                                            <Text strong>Адрес:</Text>
                                            <Text>{selectedClient.address || 'Отсутствует'}</Text>
                                        </Space>
                                        <Space>
                                            <FiCamera style={{ marginRight: 8, fontSize: 20 }} />
                                            <Text strong>Фото:</Text>
                                            {selectedClient.client_card.photo ? (
                                                <img
                                                    src={selectedClient.client_card.photo}
                                                    alt="Фото"
                                                    style={{ width: '100%', borderRadius: 8 }}
                                                />
                                            ) : (
                                                <Text>Отсутствует</Text>
                                            )}
                                        </Space>
                                        <Space>
                                            <FiFileText style={{ marginRight: 8, fontSize: 20 }} />
                                            <Text strong>Документ:</Text>
                                            {selectedClient.client_card.document ? (
                                                <Button
                                                    type="link"
                                                    href={selectedClient.client_card.document}
                                                    target="_blank"
                                                    style={{ padding: 0 }}
                                                >
                                                    Открыть документ
                                                </Button>
                                            ) : (
                                                <Text>Отсутствует</Text>
                                            )}
                                        </Space>
                                        <Space>
                                            <Text strong>Количество бутылок:</Text>
                                            <Text>{selectedClient.client_card.bottles_count}</Text>
                                        </Space>
                                        <Space>
                                            <Text strong>Кешбэк:</Text>
                                            <Text>{selectedClient.client_card.cashback}</Text>
                                        </Space>
                                    </Space>
                                </div>
                            </>
                        )}


                        {editMode && (
                            <>
                                <Divider orientation="left">Редактирование</Divider>
                                <Form form={form} layout="vertical" onFinish={updateClient} style={{ backgroundColor: "#ffffff", padding: 16, borderRadius: 8 }}>
                                    <Form.Item name="fullname" label="Имя" rules={[{ required: true, message: "Введите имя" }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="phoneNumber" label="Номер телефона" rules={[{ required: true, message: "Введите номер телефона" }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="address" label="Адрес">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="cashback" label="Кешбэк">
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item name="bottles_count" label="Количество бутылок">
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item name="second_address" label="Второй адрес">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="photo" label="Фото">
                                        <Upload name="photo" listType="picture" maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Загрузить</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item name="document" label="Документ">
                                        <Upload name="document" listType="picture" maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Загрузить</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block>
                                            Сохранить
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </>
                )}
            </Drawer>
        </div>
    );
}
