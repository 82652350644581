import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";

export default function Main({page}) {

    useEffect(() => {
    }, [page])
    return <div className={'w-full h-full pb-12'}>
        <Header />
        {page}
    </div>

}
