// src/authService.js
import api from './api';

// Функция для входа
export const login = async (credentials) => {
    try {
        const response = await api.post('api/login', credentials);
        localStorage.setItem('user',JSON.stringify(response.data.user))
        localStorage.setItem('phoneNumber',credentials.phoneNumber)
        localStorage.setItem('enc',credentials.password)
        localStorage.setItem('token',response.data.token)
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : 'Ошибка входа');
    }
};

